import { format } from 'date-fns';
import { nl } from 'date-fns/locale';

export default (date: string, withTime: boolean = false, formatAs: string = 'd MMMM yyyy') => {
  const formatAsWithTime = withTime ? 'd MMMM yyyy HH:mm' : formatAs;
  const formattedDate = format(new Date(date), formatAsWithTime, {
    locale: nl,
  });

  if (!formattedDate) {
    return date;
  }

  return formattedDate;
};
